import MusicConstants from './MusicConstants';
import AQTLocales from "./AQTLocales";
const AppConstants = {

  ENVIRONMENT: 'ENV',
  USER: 'USER',
  SESSION: 'SESSION',
  TESTPARAMS: 'TESTPARAMS',
  NOISELEVEL: '70',
  LABSTHINGS: 'LABSTHINGS',
  LIVEFEED: 'LIVEFEED',
  SILENCE: 'SILENCE',
  KITCHEN: 'KITCHEN',
  LAPTOP: 'LAPTOP',
  DEVICE: 'DEVICE',
  CAR: 'CAR',
  OFFICE: 'OFFICE',
  CAFETERIA: 'CAFETERIA',
  NOISE: 'NOISE',
  PINK: 'PINK',
  PUB: 'PUB',
  ROCK: 'ROCK',
  ALL: 'ALL',
  RESET: 'RESET',
  TEST_CASES: 'Test Cases',
  UNKNOWN: 'Unknown',
  FINISH: 'Finish',
  NEXT: 'Next',
  RESULTS: 'results',
  LINK: 'downloadLink',
  UTTERANCETIME: 18,
  ONLINE: ' Online',
  OFFLINE: ' Offline',
  SAVE: 'Save',
  DELETE: 'Delete',
  FINISHED: 'Finished',
  NOT_APPLICABLE: 'N/A',
  IN_PROGRESS: 'inprogress',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  COMPLETED: 'completed',
  RUNNING: 'running',
  CANCELED: 'canceled',
  PAUSED: 'paused',
  RESUME: 'RESUMED',
  CUSTOM: 'Custom',
  FAR: 'AC_FAR',
  FAR_PLUS : 'AC_FAR_PLUS',
  FAR_CUSTOM: 'AC_FAR_CUSTOM',
  CUSTOM_STANDARD: 'CUSTOM_STANDARD',
  LATENCY: 'AC_WWDD_QUICK_TEST',
  LATENCY_FULL: 'AC_WWDD_FULL_TEST',
  ONE_DAY_DURATION: 86400000,
  REFRESH_DURATION_FAR_STABILITY: 1000,
  ACOUSTIC_SCENARIO_ID: 'ACOUSTIC',
  MUSIC_SCENARIO_ID: 'MUSIC',
  AUTOMOTIVE_SCENARIO_ID: 'AUTOMOTIVE',
  FUNCTIONAL_SCENARIO_ID: 'FUNCTIONAL',
  STABILITY_SCENARIO_ID: 'STABILITY',
  ACAV_SCENARIO_ID: 'ACAV',
  UPL_SCENARIO_ID: 'UPL',
  QUAL_SCENARIO_ID: 'QUAL',
  CUSTOM_SCENARIO_ID: 'CUSTOM_TEST',
  CLOSE_TALK_SCENARIO_ID: 'CLOSE_TALK',
  MOBILE_SUITE_ID: 'MOBILE',
  SECURITY_SCENARIO_ID: 'AVS_SECURITY',
  AUTO_LOCAL_SEARCH_SUITE_ID: 'AUTO_LOCAL_SEARCH',
  AUTO_LOCAL_SEARCH_SCENARIO_FULL: 'FULL',
  ACOUSTIC_SCENARIO_LABEL: 'Acoustic Scenario',
  AUTOMOTIVE_SCENARIO_LABEL: 'Automotive Scenario',
  CLOSE_TALK_SCENARIO_LABEL: 'Close Talk Scenario',
  MOBILE_SCENARIO_LABEL: 'Mobile Scenario',
  AUTO_LOCAL_SEARCH_SCENARIO_LABEL: 'Auto Local Search Scenario',
  FUNCTIONAL_SCENARIO_LABEL: 'Functional Scenario',
  STABILITY_SCENARIO_LABEL: 'Stability Scenario',
  ACAV_SCENARIO_LABEL: 'Acav Scenario',
  UPL_SCENARIO_LABEL: 'UPL Scenario',
  QUAL_SCENARIO_LABEL: 'Qual Scenario',
  CUSTOM_SCENARIO_LABEL: 'User Defined Test Scenario',
  SECURITY_SCENARIO_LABEL: 'AVS Security Scenario',
  TEST_SUITE_LABEL: 'Test Suite',
  DEVICE_VIRTUAL_ASSISTANT_LABEL: 'Device Virtual Assistant',
  LOCATIONS_LABEL: 'Select Spoofed Location',
  LOCALE_LABEL: 'Locale',
  LOCATIONS_ID : 'spoofedLocation',
  LOCALE_ID: 'marketPlace',
  ADMIN_MEASURE: 'ADMIN_NO_RECORD',
  DEEZER_DEPRECATED: 'Deezer',
  UNAVAILABLE: 'Unavailable',
  //TODO: after e2e testing, remove it.
  //DEFAULT_DUT_TYPE: 'ALEXA_3P', looks like no longer being used
  FIRMWARE: 'Firmware',
  MULTIMODAL_SCENARIO_ID: 'MULTIMODAL',
  DELAY_BETWEEN_UTTERANCES_MIN: '1',
  DELAY_BETWEEN_UTTERANCES_MAX: '180',
  DELAY_BETWEEN_UTTERANCES_DEFAULT: '1',
  DELAY_BETWEEN_UTTERANCES_NON_CUSTOM: '0',
  RELEASE_NOTES_NUM_RECORDS: 25,
  RELEASE_NOTES_LATEST_RECORD: 1,
  RELEASE_NOTES_ID_KEY: 'AQT',
  MAX_OPTIONAL_DUTS: 4,
  MAX_FAR_ALLOWED_WAKES: 3,
  MAX_STABILITY_ALLOWED_PASS_RATE: 90,
  DUT_IN_USE_PREFIX: ' (Used by DUT-',
  UNKNOWN_RELEASE_NOTES: 'UNKNOWN',

  JOB_STATES: ['succeeded', 'failed'],
  QUEUED_STATES: ['requested', 'running', 'loading'],
  IN_PROGRESS_STATES: ['started', 'inprogress', 'paused', 'resume'],
  COMPLETED_STATES: ['completed', 'succeeded', 'failed', 'canceled'],
  FAR_TEST_TYPES: ['AC_FAR', 'AC_FAR_CUSTOM', 'AC_FAR_PLUS', 'FAR', 'FAR_CUSTOM'],
  SCENARIO_WITH_TEST_TYPES: ['AC_FAR_FIELD', 'AC_NEAR_FIELD', 'AC_MOBILE', 'AC_TRAINED_MOBILE', 'AC_VOICE_ENROLLMENT_ROBUSTNESS',
    'FARFIELD', 'NEARFIELD', 'MOBILE', 'MOBILE_FUNCTIONAL', 'TRAINED_MOBILE', 'CT_WAKE_AND_RESPONSE','WAKEWORD_DEVICE_VALIDATION_TEST'],
  SCENARIOS_WITH_TRAINERS: ['AC_TRAINED_MOBILE', 'AC_VOICE_ENROLLMENT_ROBUSTNESS'],
  STABILITY_TEST_TYPES: ['STABILITY_STRESS'],
  OVERRIDE_LIVE_RUN_PAYLOAD_TEST_TYPES: ['AC_VOICE_ENROLLMENT_ROBUSTNESS'],
  ACAV_TEST_TYPES: ['ACAV_SEND_SOUND_LEVEL', 'ACAV_RECEIVE_SOUND_LEVEL'],
  UPL_TEST_TYPES: ['UPL_STANDARD', 'UPL_QUICK'],
  AUTOMOTIVE_TEST_TYPES: ['AUTOMOTIVE_FRR_RAR', 'AUTOMOTIVE_WWDD', 'AUTOMOTIVE_FAR', 'AUTOMOTIVE_UPL'],
  WHITELISTED_SCENARIO_TYPES: ['AVSCertSimulation', 'AVSCertMusic', 'AQTFunctional', 'AVSCertCloseTalk', 'AQTCustomScenario', 'AQTUDTV2FARPlus',
    'AVSCertMobile', 'AVSSecurityTools', 'AQTScenarioAutoLocalSearch', 'AQTStabilityScenario', 'AQTQualScenario', 'AQTAutomotiveScenario', 'ABSPhysicalLabAcav', 'AQTUDTV2'],
  ACOUSTIC_JAR_NAME: 'AVSCertSimulation',
  AUTOMOTIVE_JAR_NAME: 'AQTAutomotiveScenario',
  MOBILE_JAR_NAME: 'AVSCertMobile',
  FAR_JAR_NAME: 'AVSCertFAR',
  FUNCTIONAL_JAR_NAME: 'AQTFunctional',
  STABILITY_JAR_NAME: 'AQTStabilityScenario',
  ACAV_JAR_NAME: 'ABSPhysicalLabAcav',
  UPL_JAR_NAME: 'AQTUPLScenario',
  AVS_SECURITY_JAR_NAME: 'AVSSecurityTools',
  ACM: 'AQTUDTV2',
  CUSTOM_SCENARIO_JAR_NAME: 'AQTCustomScenario',
  MEASURE_TYPE_ASR: 'MUSIC_UTT_ASR',
  SUCCESS: 'Success',
  FAIL: 'Fail',

  RAS_PI_CONTROL_NAME: 'PCM',
  HIFI_PI_CONTROL_NAME: 'Digital',

  NOISE_TYPES: ['Silence', 'External Music', 'Noise', 'Device Playback'],
  NOISE_TYPES_MOBILE: ['Silence', 'External Music', 'Noise', 'Device Playback', 'Car Noise'],
  NOISE_TYPES_MOBILE_VOICE_TRAINING: ['Silence', 'External Music', 'Noise', 'Device Playback', 'Car Noise', 'Pub'],
  NOISE_TYPES_MOBILE_VOICE_ROBUSTNESS: ['Silence', 'External Music', 'Cafeteria', 'Office'],
  NOISE_LEVELS_MOBILE_VOICE_ROBUSTNESS: ['35 dBC', '45 dBC', '55 dBC'],
  NOISE_TYPES_CLOSE_TALK: ['Silence', 'Pink', 'Rock Music', 'Device Playback'],
  NOISE_TYPES_AUTOMOTIVE: ['Traffic Light Mode', 'Medium Speed Mode', 'Medium Speed with Windows Open', 'Medium Speed with Device Playback', 'High Speed Mode', 'High Speed with Device Playback'],
  NEAR_ACTORS: ['0.9 m 90 deg', '0.9 m 30 deg'],
  FAR_ACTORS: ['2.7 m 90 deg', '2.7 m 30 deg'],
  MOBILE_FUNCTIONAL_ACTORS:['0.9 m 30 deg'],
  WAKEWORD_DEVICE_VALIDATION_ACTORS:['0.9 m 90 deg'],
  TRAINED_MOBILE_USERS: ['andrew', 'brittany', 'caleb', 'danielle', 'eric', 'fiona',
    'gerard', 'hannah', 'indiana', 'jamie'],
  GENERIC_TRAINED_MOBILE_USERS: [
                                 'P001', 'P002', 'P003', 'P004', 'P005', 'P006', 'P007', 'P008', 'P009', 'P010',
                                 'P011', 'P012', 'P013', 'P014', 'P015', 'P016', 'P017', 'P018', 'P019', 'P020',
                                 'P021', 'P022', 'P023', 'P024', 'P025', 'P026', 'P027', 'P028', 'P029', 'P030',
                                 'P031', 'P032', 'P033', 'P034', 'P035', 'P036', 'P037', 'P038', 'P039', 'P040',
                                 'P041', 'P042', 'P043', 'P044', 'P045', 'P046', 'P047', 'P048', 'P049', 'P050',
  ],
  // Add any locales that use the new mobile convention of 50 speakers here
  OVERHAULED_MOBILE_LOCALES: ['pt_BR'],

  MOBILE_OVERHAUL_PARTNERS: [
    "a1a4e650-f51c-4d22-b03d-03f4a810ea05",
    "9d48ad3b-c544-4e4a-b363-cb26a7c7c32a",
    "e6a5b08f-8552-4d73-9812-29536cf180fb",
    "1979de9d-29a3-4fc8-a7c3-9a0ba67bd0fc",
    "80ec6d1a-325e-430c-8f2d-46989dc72899",
    "685620d6-a379-49ec-b3a3-beffaec27d04",
    "7d7ca0ac-2afe-4f98-b259-9aa171d6f872",
    "065f8b8a-e007-42aa-9ab4-5ef92cf18813",
    "59a7882f-865d-43eb-bea2-fce2133ecf27",
    "00906e17-b01d-4ab4-8175-14b89fc594ca",
    "52e37b0d-c2cf-451e-bef1-da35a736d6df",
    "948c8230-fb7d-461a-a8e1-942573543c2f",
    "b0f8f1f1-7f47-4a3e-9ec4-a9db78b00b2e"
  ],
  NOISE_LOCATION: '1.8 m 135 deg',
  CT_NOISE_LOCATION: '2.0 m 90 deg',
  NOISE_TYPE_SILENCE: 'Silence',
  NOISE_TYPE_DEVICE_PLAYBACK: 'Device Playback',
  AUTOMOTIVE_NOISE_TYPES: ['Device Playback'],
  AUTOMOTIVE_LOCATION: 'Automotive Companion',
  QUAL_RF_LOCATION: 'Qual and RF Companion',
  SCENARIO_UBUNTU_LOCATION: 'ubuntu machine',
  CT_HATS_LOCATION: 'HATS (Head & Torso Simulator)',
  LOCATION_THREE_NINETY: '0.9 m 90 deg',
  PROD_ENDPOINT: 'https://api.us-west-2.amazon-aqt.com',
  BETA_ENDPOINT: 'https://api.us-west-2.beta.amazon-aqt.com',
  ALPHA_ENDPOINT_EAST: 'https://api.us-east-2.alpha.amazon-aqt.com',
  ALPHA_ENDPOINT_WEST: 'https://api.us-west-2.alpha.amazon-aqt.com',
  LOCAL_ENDPOINT: 'http://localhost:8080',
  SECURITY_TEST_HEADER: 'Security Test',
  MARKETPLACE_EN_US: 'en_US',
  PROGRESS_BAR_STARTED_WIDTH: 5,
  PROGRESS_BAR_IN_PROGRESS_WIDTH: 10,
  PROGRESS_BAR_COMPLETED_WIDTH: 100,
  PROGRESS_BAR_IN_PROGRESS_OFFSET: 0.8,
  AQT_COMMON_MANIFEST_FILE: 'acoustic_common_audiofiles_manifest.json',
  QUAL_COMMON_MANIFEST_FILE: 'qual_common_audiofiles_manifest.json',
  MOBILE_COMMON_MANIFEST_FILE: 'mobile_common_audioFiles_manifest.json',

  // This is the Device Type for all A4PC applications
  A4PC_DEVICE_TYPE_ID: 'A1RTAM01W29CUP',

  TEST_SUITE_LIST_ENABLED_FOR_HOTSPOT: ['AVS_SECURITY'],

  TEST_SUITE_MAP: {
    'AVSCertSimulation': 'ACOUSTIC',
    'AVSCertMusic': 'MUSIC',
    'AQTFunctional': 'FUNCTIONAL',
    'AVSCertCloseTalk': 'CLOSE_TALK',
    'AVSCertMobile': 'MOBILE',
    'AQTScenarioAutoLocalSearch': 'AUTO_LOCAL_SEARCH',
    'AVSCertFAR': 'ACOUSTIC',
    'AQTStabilityScenario': 'STABILITY',
    'AQTUPLScenario': 'UPL',
    'AQTQualScenario': 'QUAL',
    'AVSSecurityTools': 'AVS_SECURITY',
    'AQTCustomScenario': 'CUSTOM_TEST',
    'AQTAutomotiveScenario': 'AUTOMOTIVE',
    'ABSPhysicalLabAcav': 'ACAV',
    'AQTUDTV2FARPlus': 'FAR_PLUS'
  },

  // Map to display message in UI specific to Admin utterance being played while test in running
  ADMIN_UTTERANCE_MESSAGE_MAP: {
    'StopIntent': 'Running prerequisite conditions for the test ... Initializing device for the test scenario',
    'PlayMusicIntent': 'Running prerequisite conditions for the test ... Starting device playback',
    'MusicControlIntent': 'Running prerequisite conditions for the test ... Looping device playback content',
    'RemoveItemFromShoppingListIntent': 'Running prerequisite conditions for the test ... Clearing shopping list',
    'RemoveItemFromToDoListIntent': 'Running prerequisite conditions for the test ... Clearing to-do list'
  },

  // Map to display orientation diagram based on scenario type or test suite
  ORIENTATION_RESOURCE_MAP: {
    'AC_FAR_FIELD': 'FF_Orientation.png',
    'AC_NEAR_FIELD': 'NF_Orientation.png',
    'AC_WWDD_QUICK_TEST': 'WWDD_Orientation.png',
    'AC_WWDD_FULL_TEST': 'WWDD_Orientation.png',
    'AC_FAR': 'FAR_Orientation.png',
    'AC_FAR_PLUS': 'FAR_Orientation.png',
    'AC_FAR_CUSTOM': 'FAR_Orientation.png',
    'AC_TRAINED_MOBILE': 'Trained_Mobile_Orientation.png',
    'CLOSE_TALK': 'CloseTalkCanvas.png',
    'MUSIC': 'WWDD_Orientation.png',
    'QUAL': 'Qual_Orientation.png'
  },
  RASPI_RESOURCES_SYNCING: 'lab_resources_syncing',
  // States which represent RasPi update is in progress
  RASPI_UPDATING_STATES: ['lab_code_updating', 'lab_resources_syncing'],

  // States which represent RasPi update is completed
  RASPI_UPDATED_STATES: ['lab_code_updated', 'lab_resources_synced'],

  // Map to have display friendly names for RasPi states in Live Run page progress bar
  RASPI_STATES_DISPLAY: {
    'lab_code_updating': 'RasPi: Updating Software',
    'lab_code_updated': 'RasPi: Completed Software Updates',
    'lab_resources_syncing': 'RasPi: Downloading Resources',
    'lab_resources_synced': 'RasPi: Completed downloading Resources'
  },

  // Mapping for scenario type to be displayed on TestOptions page once 'Submit' button on
  // New run page is clicked
  TEST_OPTIONS_SCENARIO_TYPE_DISPLAY: {
    'ACOUSTIC': 'Acoustic Scenario',
    'MUSIC': 'Music Scenario',
    'FUNCTIONAL': 'Functional Scenario',
    'CLOSE_TALK': 'Close Talk Scenario',
    'MOBILE': 'Mobile Scenario',
    'AUTO_LOCAL_SEARCH': 'Auto Local Search Scenario',
    'STABILITY': 'Stability Scenario',
    'UPL': 'UPL Scenario',
    'QUAL': 'Qualification Test Suite',
    'AVS_SECURITY': 'Security Scenario',
    'CUSTOM_TEST': 'User Defined Test Scenario',
    'AUTOMOTIVE': 'Automotive Scenario',
    'ACAV': 'Acav Scenario'
  },

  /**
   * Default list of whitelisted scenarios for a particular account
   */
  WHITELISTED_SCENARIOS: [{
    id: 'ACOUSTIC',
    label: 'Acoustic',
    tags: ['Enables Acoustic test scenarios']
  }, {
    id: 'MUSIC',
    label: 'Music',
    tags: ['Enables Music test scenarios']
  }, {
    id: 'FUNCTIONAL',
    label: 'Functional',
    tags: ['Enables Functional test scenarios']
  }, {
    id: 'CLOSE_TALK',
    label: 'Close Talk',
    tags: ['Enables Close Talk test scenarios']
  }, {
    id: 'MOBILE',
    label: 'Mobile',
    tags: ['Enables Mobile test scenarios']
  }, {
    id: 'AUTO_LOCAL_SEARCH',
    label: 'Auto Local Search',
    tags: ['Enables Auto Local Search test scenario']
  }, {
    id: 'STABILITY',
    label: 'Stability',
    tags: ['Enables Stability test scenario']
  }, {
    id: 'UPL',
    label: 'UPL',
    tags: ['Enables UPL test scenario']
  }, {
    id: 'QUAL',
    label: 'Qualification Test Suite',
    tags: ['Enables Qualification test scenarios']
  }, {
    id: 'AVS_SECURITY',
    label: 'Security Test Suite',
    tags: ['Enables Security test scenarios']
  }, {
    id: 'CUSTOM_TEST',
    label: 'User Defined Test',
    tags: ['Enables user defined test scenarios']
  }, {
    id: 'AUTOMOTIVE',
    label: 'Automotive',
    tags: ['Enables Automotive test scenarios']
  }, {
    id: 'ACAV',
    label: 'Acav',
    tags: ['Enables Acav test scenario']
  }],

  SCENARIO_TYPE: [{
    id: 'AC_FAR_FIELD',
    label: 'Far Field',
    tags: ['Runs the Far-Field acoustic scenario']
  }, {
    id: 'AC_NEAR_FIELD',
    label: 'Near Field',
    tags: ['Runs the Near-Field acoustic scenario']
  }, {
    id: 'AC_WWDD_QUICK_TEST',
    label: 'WWDD - Quick Test',
    tags: ['Runs the Quick Wake-Word Detection Delay scenario - Stops as soon as the minimum latency value is found']
  }, {
    id: 'AC_WWDD_FULL_TEST',
    label: 'WWDD - Full Test',
    tags: ['Runs the Full Wake-Word Detection Delay scenario']
  }, {
    id: 'AC_FAR',
    label: 'FAR',
    tags: ['Runs the Wake Word False Acceptance Rate scenario']
  }, {
    id: 'AC_FAR_CUSTOM',
    label: 'FAR - Custom',
    tags: ['Runs the Custom Wake Word False Acceptance Rate scenario, for up to 5 devices']
  }, {
    id: 'WAKEWORD_DEVICE_VALIDATION_TEST',
    label: 'Wakeword Device Validation Test',
    tags: ['Run wakeword diagnostic and 500ms pre-roll verification test'],
  }],

  ACOUSTIC_SCENARIO_TYPE_PROD_ALLOWLISTED: [{
    id: 'AC_FAR_FIELD',
    label: 'Far Field',
    tags: ['Runs the Far-Field acoustic scenario']
  }, {
    id: 'AC_NEAR_FIELD',
    label: 'Near Field',
    tags: ['Runs the Near-Field acoustic scenario']
  }, {
    id: 'AC_WWDD_QUICK_TEST',
    label: 'WWDD - Quick Test',
    tags: ['Runs the Quick Wake-Word Detection Delay scenario - Stops as soon as the minimum latency value is found']
  }, {
    id: 'AC_WWDD_FULL_TEST',
    label: 'WWDD - Full Test',
    tags: ['Runs the Full Wake-Word Detection Delay scenario']
  }, {
    id: 'AC_FAR',
    label: 'FAR',
    tags: ['Runs the Wake Word False Acceptance Rate scenario']
  }, {
    id: 'AC_FAR_PLUS',
    label: 'FAR - Plus',
    tags: ['Runs the Wake Word False Acceptance Rate scenario(New Version)']
  }, {
    id: 'AC_FAR_CUSTOM',
    label: 'FAR - Custom',
    tags: ['Runs the Custom Wake Word False Acceptance Rate scenario, for up to 5 devices']
  }, {
    id: 'WAKEWORD_DEVICE_VALIDATION_TEST',
    label: 'Wakeword Device Validation Test',
    tags: ['Run wakeword diagnostic, 500ms pre-roll verification and audioInitiator test'],
  }],

  ACOUSTIC_SCENARIO_TYPE_ALPHA: [{
    id: 'AC_FAR_FIELD',
    label: 'Far Field',
    tags: ['Runs the Far-Field acoustic scenario']
  }, {
    id: 'AC_NEAR_FIELD',
    label: 'Near Field',
    tags: ['Runs the Near-Field acoustic scenario']
  }, {
    id: 'AC_WWDD_QUICK_TEST',
    label: 'WWDD - Quick Test',
    tags: ['Runs the Quick Wake-Word Detection Delay scenario - Stops as soon as the minimum latency value is found']
  }, {
    id: 'AC_WWDD_FULL_TEST',
    label: 'WWDD - Full Test',
    tags: ['Runs the Full Wake-Word Detection Delay scenario']
  }, {
    id: 'AC_FAR',
    label: 'FAR',
    tags: ['Runs the Wake Word False Acceptance Rate scenario']
  }, {
    id: 'AC_FAR_CUSTOM',
    label: 'FAR - Custom',
    tags: ['Runs the Custom Wake Word False Acceptance Rate scenario, for up to 5 devices']
  }, {
    id: 'AC_FAR_PLUS',
    label: 'FAR - Plus',
    tags: ['Runs the Wake Word False Acceptance Rate scenario(New Version)']
  }, {
    id: 'WAKEWORD_DEVICE_VALIDATION_TEST',
    label: 'Wakeword Device Validation Test',
    tags: ['Run wakeword diagnostic, 500ms pre-roll verification and audioInitiator test'],
  }],

  ACOUSTIC_SCENARIO_TYPE_BETA: [{
    id: 'AC_FAR_FIELD',
    label: 'Far Field',
    tags: ['Runs the Far-Field acoustic scenario']
  }, {
    id: 'AC_NEAR_FIELD',
    label: 'Near Field',
    tags: ['Runs the Near-Field acoustic scenario']
  }, {
    id: 'AC_WWDD_QUICK_TEST',
    label: 'WWDD - Quick Test',
    tags: ['Runs the Quick Wake-Word Detection Delay scenario - Stops as soon as the minimum latency value is found']
  }, {
    id: 'AC_WWDD_FULL_TEST',
    label: 'WWDD - Full Test',
    tags: ['Runs the Full Wake-Word Detection Delay scenario']
  }, {
    id: 'AC_FAR',
    label: 'FAR',
    tags: ['Runs the Wake Word False Acceptance Rate scenario']
  }, {
    id: 'AC_FAR_CUSTOM',
    label: 'FAR - Custom',
    tags: ['Runs the Custom Wake Word False Acceptance Rate scenario, for up to 5 devices']
  }, {
    id: 'AC_FAR_PLUS',
    label: 'FAR - Plus',
    tags: ['Runs the Wake Word False Acceptance Rate scenario(New Version)']
  }, {
    id: 'WAKEWORD_DEVICE_VALIDATION_TEST',
    label: 'Wakeword Device Validation Test',
    tags: ['Run wakeword diagnostic, 500ms pre-roll verification and audioInitiator test'],
  }],

  // Close talk scenario types
    CLOSE_TALK_SCENARIO_TYPE: [{
    id: 'CT_WAKE_AND_RESPONSE',
    label: 'Wake & Response Accuracy',
    tags: ['Runs the Wake & Response Accuracy scenario (FRR/RAR)']
  }, {
    id: 'AC_WWDD_QUICK_TEST',
    label: 'WWDD - Quick Test',
    tags: ['Runs the Quick Wake-Word Detection Delay scenario - Stops as soon as the minimum latency value is found']
  }, {
    id: 'AC_WWDD_FULL_TEST',
    label: 'WWDD - Full Test',
    tags: ['Runs the Full Wake-Word Detection Delay scenario']
  }, {
    id: 'AC_FAR',
    label: 'FAR',
    tags: ['Runs the Wake Word False Acceptance Rate scenario']
  }],

  // Mobile scenario types
  MOBILE_SCENARIO_TYPE: [{
    id: 'AC_TRAINED_MOBILE',
    label: 'Wake & Response Accuracy',
    tags: ['Voice enrollment with FRR/RAR/IAR testing']
  }, {
    id: 'AC_VOICE_ENROLLMENT_ROBUSTNESS',
    label: 'Voice Enrollment Robustness',
    tags: ['Voice enrollment testing under various noise conditions']
  }, {
    id: 'AC_WWDD_QUICK_TEST',
    label: 'WWDD - Quick Test',
    tags: ['Runs the Quick Wake-Word Detection Delay scenario - Stops as soon as the minimum latency value is found']
  }, {
    id: 'AC_WWDD_FULL_TEST',
    label: 'WWDD - Full Test',
    tags: ['Runs the Full Wake-Word Detection Delay scenario']
  }, {
    id: 'AC_FAR',
    label: 'FAR',
    tags: ['Runs the Wake Word False Acceptance Rate scenario']
  }, {
    id: 'MOBILE_FUNCTIONAL',
    label: 'Functional',
    tags: ['Runs functional scenarios']
  }],

  // Scenario types with old IDs - used to backfill data on results page
  SCENARIO_TYPE_DEPRECATED: [{
    id: 'FARFIELD',
    label: 'Far Field',
    tags: ['Runs the Far-Field acoustic scenario']
  }, {
    id: 'NEARFIELD',
    label: 'Near Field',
    tags: ['Runs the Near-Field acoustic scenario']
  }, {
    id: 'LATENCY',
    label: 'WWDD - Quick Test',
    tags: ['Runs the Quick Wake Word Delay Detection scenario - Stops as soon as minimum latency value is found']
  }, {
    id: 'LATENCY_FULL',
    label: 'WWDD - Full Test',
    tags: ['Runs the full WWDD Wake Word Delay Detection scenario']
  }, {
    id: 'FAR',
    label: 'FAR',
    tags: ['Runs the Wake Word False Alarm Rate scenario']
  }, {
    id: 'FAR_CUSTOM',
    label: 'FAR - Custom',
    tags: ['Runs the Wake Word False Alarm Rate Custom scenario for upto 5 devices']
  }, {
    id: 'MOBILE',
    label: 'Mobile',
    tags: ['Runs the Mobile acoustic scenario']
  }, {
    id: 'AC_TRAINED_MOBILE',
    label: 'Mobile Voice Training',
    tags: ['Runs the Mobile Voice Training scenario']
  }],

  // Functional Scenario types
  FUNCTIONAL_SCENARIO_TYPE: [{
    id: 'FUNC_ALL',
    label: 'Standard',
    tags: ['Runs All Functional scenarios']
  }, {
    id: 'FUNC_CUSTOM',
    label: 'Custom',
    tags: ['Runs Customized Functional scenarios']
  }, {
    id: 'AUTO_FUNC_ALL',
    label: 'Automotive Functional',
    tags: ['Runs Automotive Functional scenarios']
  }, {
    id: 'AUTO_FUNC_CUSTOM',
    label: 'Custom Automotive Functional',
    tags: ['Runs Customized Automotive Functional scenarios']
  }, {
    id: 'FUNC_NAV_ALL',
    label: 'Automotive Navigation Standard',
    tags: ['Runs All Functional Navigation scenarios']
  }, {
    id: 'FUNC_NAV_CUSTOM',
    label: 'Automotive Navigation Custom',
    tags: ['Runs Customized Functional Navigation scenarios']
  }],

  AVS_SECURITY_SCENARIO_TYPE: [{
    id: 'PORT_SCANNER',
    label: 'Port Detection',
    tags: ['Runs Port Detection Security scenario']
  }, {
    id: 'PACKET_CAPTURE_ANALYSIS',
    label: 'Packet Capture Analysis',
    tags: ['Perform Packet Capture and provide analysis']
  }, {
    id: 'MAN_IN_THE_MIDDLE_ATTACK',
    label: 'Man in the middle attack analysis',
    tags: ['Detect for Man in the middle attack']
  }],

  // Stability Scenario types
  STABILITY_SCENARIO_TYPE: [{
    id: 'STABILITY_STRESS',
    label: 'Stress Test',
    tags: ['Runs Stability Stress Scenario']
  }],

  // UPL Scenario types
  UPL_SCENARIO_TYPE: [{
    id: 'UPL_STANDARD',
    label: 'UPL Standard',
    tags: ['Runs UPL Standard Scenario']
  }, {
    id: 'UPL_QUICK',
    label: 'UPL Quick',
    tags: ['Runs UPL Quick Scenario']
  }],

  // Temporary: Functional scenarios to render only in ALPHA
  FUNCTIONAL_SCENARIO_TYPE_ALPHA: [{
    id: 'FUNC_ALL',
    label: 'Standard',
    tags: ['Runs All Functional scenarios']
  }, {
    id: 'FUNC_CUSTOM',
    label: 'Custom',
    tags: ['Runs Customized Functional scenarios']
  }, {
    id: 'MULTIMODAL',
    label: 'Multimodal',
    tags: ['Runs Multimodal Functional scenarios']
  }, {
    id: 'AUTO_FUNC_ALL',
    label: 'Automotive Functional',
    tags: ['Runs Automotive Functional scenarios']
  }, {
    id: 'AUTO_FUNC_CUSTOM',
    label: 'Custom Automotive Functional',
    tags: ['Runs Customized Automotive Functional scenarios']
  }, {
    id: 'FUNC_NAV_ALL',
    label: 'Automotive Navigation Standard',
    tags: ['Runs All Functional Navigation scenarios']
  }, {
    id: 'FUNC_NAV_CUSTOM',
    label: 'Automotive Navigation Custom',
    tags: ['Runs Customized Functional Navigation scenarios']
  }],


  // Auto Local Search scenario types
  AUTO_LOCAL_SEARCH_SCENARIO_TYPE: [{
    id: 'SMOKE',
    label: 'Smoke',
    tags: ['Runs the test for smaller subset of utterances']
  }, {
    id: 'FULL',
    label: 'Full',
    tags: ['Runs the test for complete set of utterances']
  }],

  // Auto Local Search Device virtual assistants
  AUTO_LOCAL_SEARCH_DEVICE_VIRTUAL_ASSISTANTS: [{
    id: 'ALEXA',
    label: 'Alexa',
    tags: ['Runs the test for Alexa Devices']
  }, {
    id: 'GOOGLE',
    label: 'Google',
    tags: ['Runs the test for Google Devices']
  }, {
    id: 'SIRI',
    label: 'Siri',
    tags: ['Runs the test for Siri Devices']
  }],

  /**
   * Scenario types for Customized test
   */
  CUSTOMIZED_SCENARIOS: [{
    id: 'CUSTOM_STANDARD',
    label: 'Customized - Standard',
    tags: ['Runs Customized test  with Standard set of utterances']
  }, {
    id: 'CUSTOM_QUICK',
    label: 'Customized - Quick',
    tags: ['Runs Customized test with Quick set of utterances']
  }, {
    id: 'CUSTOM_DEMO',
    label: 'Customized - Demo',
    tags: ['Runs Customized test with Demo set of utterances']
  }],

  /**
   * Automotive Scenario types
   */
  AUTOMOTIVE_SCENARIO_TYPE: [{
    id: 'AUTOMOTIVE_FRR_RAR',
    label: 'Wake & Response Accuracy',
    tags: ['Runs Automotive acoustic noise scenarios']
  }], /*{
    id: 'AUTOMOTIVE_WWDD',
    label: 'WWDD',
    tags: ['Runs Acoustic WWDD Scenario in Automotive']
  }, {
    id: 'AUTOMOTIVE_FAR',
    label: 'FAR',
    tags: ['Runs Acoustic FAR Scenario in Automotive']
  }, {
    id: 'AUTOMOTIVE_UPL',
    label: 'UPL',
    tags: ['Runs UPL Scenario in Automotive']
  }],*/

  // Acav Scenario types
  ACAV_SCENARIO_TYPE: [{
    id: 'ACAV_SEND_SOUND_LEVEL',
    label: 'Send Sound Level',
    tags: ['Runs Acav Send Sound Level Scenario']
  }, {
    id: 'ACAV_RECEIVE_SOUND_LEVEL',
    label: 'Receive Sound Level',
    tags: ['Runs Acav Receive Sound Level Scenario']
  }],

  FARFIELD: 'AC_FAR_FIELD',
  NEARFIELD: 'AC_NEAR_FIELD',
  WAKEWORD: 'WAKEWORD_DEVICE_VALIDATION_TEST',
  MOBILE: 'AC_MOBILE',
  TRAINED_MOBILE: 'AC_TRAINED_MOBILE',
  VOICE_ROBUSTNESS: 'AC_VOICE_ENROLLMENT_ROBUSTNESS',
  CT_WAKE_AND_RESPONSE: 'CT_WAKE_AND_RESPONSE',
  FUNCTIONAL: 'FUNCTIONAL',
  MOBILE_FUNCTIONAL: 'MOBILE_FUNCTIONAL',
  WAKEWORD_LAB_DEPENDENCIES_URL: "s3://aqt-utterance-resources/ManifestFiles/WWE/20230616/WAKEWORD_LAB_DEPENDENCY.json",
  FAR_PLUS_LAB_DEPENDENCIES_URL: "s3://aqt-utterance-resources/LabDependencies/FAR_PLUS/20240117/FAR_PLUS_LAB_DEPENDENCY.json",
  STANDARD: 'STANDARD',
  DEMO: 'DEMO',
  QUICK: 'QUICK',
  SMOKE: 'SMOKE',
  CUSTOMIZED: 'CUSTOMIZED',
  ALEXA_HANDS_FREE_VERIFICATION: 'ALEXA_HANDS_FREE_VERIFICATION',
  DEVICE_LOCK_HANDS_FREE_VERIFICATION: 'DEVICE_LOCK_HANDS_FREE_VERIFICATION',
  APP_VERIFICATION: 'APP_VERIFICATION',
  PIN_LOCK: 'PIN_LOCK',
  SWIPE_LOCK: 'SWIPE_LOCK',

  MARKETPLACE: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }, {
    id: 'en_CA',
    label: 'en-CA',
    tags: ['English - Canada']
  }, {
    id: 'en_AU',
    label: 'en-AU',
    tags: ['English - Australia']
  }, {
    id: 'en_GB',
    label: 'en-GB',
    tags: ['English - United Kingdom']
  }, {
    id: 'en_IN',
    label: 'en-IN',
    tags: ['English - India']
  }, {
    id: 'hi_IN',
    label: 'hi-IN',
    tags: ['Hindi - India']
  }, {
    id: 'de_DE',
    label: 'de-DE',
    tags: ['German - Germany']
  }, {
    id: 'ja_JP',
    label: 'ja-JP',
    tags: ['Japanese - Japan']
  }, {
    id: 'fr_FR',
    label: 'fr-FR',
    tags: ['French - France']
  }, {
    id: 'fr_CA',
    label: 'fr-CA',
    tags: ['French - Canada']
  }, {
    id: 'es_ES',
    label: 'es-ES',
    tags: ['Spanish - Spain']
  }, {
    id: 'es_MX',
    label: 'es-MX',
    tags: ['Spanish - Mexico']
  }, {
    id: 'es_US',
    label: 'es-US',
    tags: ['Spanish - United States']
  }, {
    id: 'it_IT',
    label: 'it-IT',
    tags: ['Italian - Italy']
  }, {
    id: 'pt_BR',
    label: 'pt-BR',
    tags: ['Portuguese - Brazil']
  }, {
    id: 'ar_SA',
    label: 'ar-SA',
    tags: ['Arabic - Saudi Arabia']
  }],

  // DEFAULT MUSIC LOCALE DROPDOWN OPTIONS
  MARKETPLACE_MUSIC: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }, {
    id: 'de_DE',
    label: 'de-DE',
    tags: ['German - Germany']
  }, {
    id: 'en_GB',
    label: 'en-GB',
    tags: ['English - United Kingdom']
  }, {
    id: 'ja_JP',
    label: 'ja-JP',
    tags: ['Japaneses - Japan']
  }],

  // Locale values for FAR+ test suite
  // Todo: Add more locales after grading criteria is finalized for other locales.
  // Note: This can be removed after all locales have been added to FAR+.
  MARKETPLACE_FAR_PLUS: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }, {
    id: 'de_DE',
    label: 'de-DE',
    tags: ['German - Germany']
  }, {
    id: 'en_GB',
    label: 'en-GB',
    tags: ['English - United Kingdom']
  }, {
    id: 'ja_JP',
    label: 'ja-JP',
    tags: ['Japaneses - Japan']
  }, {
    id: 'fr_FR',
    label: 'fr-FR',
    tags: ['French - France']
  }, {
    id: 'fr_CA',
    label: 'fr-CA',
    tags: ['French - Canada']
  }, {
    id: 'es_ES',
    label: 'es-ES',
    tags: ['Spanish - Spain']
  }, {
    id: 'it_IT',
    label: 'it-IT',
    tags: ['Italian - Italy']
  }, {
    id: 'es_MX',
    label: 'es-MX',
    tags: ['Spanish - Mexico']
  }, {
    id: 'es_US',
    label: 'es-US',
    tags: ['Spanish - United States']
  }, {
    id: 'pt_BR',
    label: 'pt-BR',
    tags: ['Portuguese - Brazil']
  }, {
    id: 'en_IN',
    label: 'en-IN',
    tags: ['English - India']
  }, {
    id: 'hi_IN',
    label: 'hi-IN',
    tags: ['Hindi - India']
  }, {
    id: 'en_AU',
    label: 'en-AU',
    tags: ['English - Australia']
  }],

  // Locale values for FAR+ test for prod
  MARKETPLACE_FAR_PLUS_PROD: [AQTLocales.enUS, AQTLocales.deDE, AQTLocales.itIT, AQTLocales.frCA, AQTLocales.frFR, AQTLocales.esMX, AQTLocales.esUS, AQTLocales.esES, AQTLocales.enGB, AQTLocales.jaJP, AQTLocales.enIN, AQTLocales.enAU, AQTLocales.hiIN, AQTLocales.ptBR],

  // Locale values for Functional test suite
  MARKETPLACE_FUNCTIONAL: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }],

  // Available Spoofed location values for Nav Functional test suite
  AVAILABLE_LOCATIONS_EN_US: [{
    id: 'SanJose',
    label: 'San Jose (Default)',
    tags: ['37.40233,-121.89817']
  }],

  // Locale values for Stability test suite
  MARKETPLACE_STABILITY: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }],

  // Locale values for UPL test suite
  MARKETPLACE_UPL: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }],

  // Locale values for Custom test suite - Polly only. Non-polly can be any locale
  // These locales has support for neural voice https://docs.aws.amazon.com/polly/latest/dg/ntts-voices-main.html
  MARKETPLACE_CUSTOM_POLLY: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }, {
    id: 'en_GB',
    label: 'en-GB',
    tags: ['English - United Kingdom']
  }, {
    id: 'en_AU',
    label: 'en-AU',
    tags: ['English - Australia']
  }, {
    id: 'pt_BR',
    label: 'pt-BR',
    tags: ['Portuguese - Brazil']
  }, {
    id: 'es_US',
    label: 'es-US',
    tags: ['Spanish - United States']
  }, {
    id: 'ar_AE',
    label: 'ar-AE',
    tags: ['Arabic - Gulf']
  }, {
    id: 'nl_BE',
    label: 'nl-BE',
    tags: ['Belgian Dutch - Flemish']
  }, {
    id: 'ca_ES',
    label: 'ca-ES',
    tags: ['Catalan']
  }, {
    id: 'yue_CN',
    label: 'yue-CN',
    tags: ['Chinese - Cantonese']
  }, {
    id: 'cmn_CN',
    label: 'cmn-CN',
    tags: ['Chinese - Mandarin']
  }, {
    id: 'da_DK',
    label: 'da-DK',
    tags: ['Danish']
  }, {
    id: 'nl_NL',
    label: 'nl-NL',
    tags: ['Dutch']
  }, {
    id: 'en_IN',
    label: 'en-IN',
    tags: ['English - Indian']
  }, {
    id: 'en_IE',
    label: 'en-IE',
    tags: ['English - Irish']
  }, {
    id: 'en_NZ',
    label: 'en-NZ',
    tags: ['English - New Zealand']
  }, {
    id: 'en_ZA',
    label: 'en-ZA',
    tags: ['English - South African']
  }, {
    id: 'fi_FI',
    label: 'fi-FI',
    tags: ['Finnish']
  }, {
    id: 'fr_BE',
    label: 'fr-BE',
    tags: ['French - Belgian']
  }, {
    id: 'fr_CA',
    label: 'fr-CA',
    tags: ['French - Canadian']
  }, {
    id: 'fr_FR',
    label: 'fr-FR',
    tags: ['French']
  }, {
    id: 'de_DE',
    label: 'de-DE',
    tags: ['German']
  }, {
    id: 'de_AT',
    label: 'de-AT',
    tags: ['German - Austrian']
  }, {
    id: 'hi_IN',
    label: 'hi-IN',
    tags: ['Hindi']
  }, {
    id: 'it_IT',
    label: 'it-IT',
    tags: ['Italian']
  }, {
    id: 'ja_JP',
    label: 'ja-JP',
    tags: ['Japanese']
  }, {
    id: 'ko_KR',
    label: 'ko-KR',
    tags: ['Korean']
  }, {
    id: 'nb_NO',
    label: 'nb-NO',
    tags: ['Norwegian']
  }, {
    id: 'pl_PL',
    label: 'pl-PL',
    tags: ['Polish']
  }, {
    id: 'pt_PT',
    label: 'pt-PT',
    tags: ['Portuguese - European']
  }, {
    id: 'es_ES',
    label: 'es-ES',
    tags: ['Spanish - European']
  }, {
    id: 'es_MX',
    label: 'es-MX',
    tags: ['Spanish - Mexican']
  }, {
    id: 'sv_SE',
    label: 'sv-SE',
    tags: ['Swedish']
  }],

  // Locale values for ACAV test suite
  MARKETPLACE_ACAV: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }],

  // Locale values for Auto Local Search test suite
  MARKETPLACE_AUTO_LOCAL_SEARCH: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }, {
    id: 'de_DE',
    label: 'de-DE',
    tags: ['German - Germany']
  }, {
    id: 'en_GB',
    label: 'en-GB',
    tags: ['English - United Kingdom']
  }],

  MARKETPLACE_TRAINED_MOBILE: [{
    id: 'en_US',
    label: 'en-US/CA',
    tags: ['English - North America']
  }, {
    id: 'en_AU',
    label: 'en-AU',
    tags: ['English - Australia']
  }, {
    id: 'en_GB',
    label: 'en-GB',
    tags: ['English - United Kingdom']
  }, {
    id: 'en_IN',
    label: 'en-IN',
    tags: ['English - India']
  }, {
    id: 'de_DE',
    label: 'de-DE',
    tags: ['German - Germany']
  }, {
    id: 'es_ES',
    label: 'es-ES',
    tags: ['Spanish - Spain']
  }, {
    id: 'es_MX',
    label: 'es-MX',
    tags: ['Spanish - Mexico']
  }, {
    id: 'es_US',
    label: 'es-US',
    tags: ['Spanish - United States']
  }, {
    id: 'fr_CA',
    label: 'fr-CA',
    tags: ['French - Canada']
  }, {
    id: 'fr_FR',
    label: 'fr-FR',
    tags: ['French - France']
  }, {
    id: 'hi_IN',
    label: 'hi-IN',
    tags: ['Hindi - India']
  }, {
    id: 'it_IT',
    label: 'it-IT',
    tags: ['Italian - Italy']
  }],

  MARKETPLACE_TRAINED_MOBILE_OVERHAULED: [{
    id: 'en_US',
    label: 'en-US/CA',
    tags: ['English - North America']
  }, {
    id: 'en_AU',
    label: 'en-AU',
    tags: ['English - Australia']
  }, {
    id: 'en_GB',
    label: 'en-GB',
    tags: ['English - United Kingdom']
  }, {
    id: 'en_IN',
    label: 'en-IN',
    tags: ['English - India']
  }, {
    id: 'de_DE',
    label: 'de-DE',
    tags: ['German - Germany']
  }, {
    id: 'es_ES',
    label: 'es-ES',
    tags: ['Spanish - Spain']
  }, {
    id: 'es_MX',
    label: 'es-MX',
    tags: ['Spanish - Mexico']
  }, {
    id: 'es_US',
    label: 'es-US',
    tags: ['Spanish - United States']
  }, {
    id: 'fr_CA',
    label: 'fr-CA',
    tags: ['French - Canada']
  }, {
    id: 'fr_FR',
    label: 'fr-FR',
    tags: ['French - France']
  }, {
    id: 'hi_IN',
    label: 'hi-IN',
    tags: ['Hindi - India']
  }, {
    id: 'it_IT',
    label: 'it-IT',
    tags: ['Italian - Italy']
  }, {
    id: 'pt_BR',
    label: 'pt-BR',
    tags: ['Portuguese - Brazil']
  }],

  MARKETPLACE_CLOSE_TALK: [{
    id: 'en_US',
    label: 'en-US',
    tags: ['English - United States']
  }, {
    id: 'en_AU',
    label: 'en-AU',
    tags: ['English - Australia']
  }, {
    id: 'en_GB',
    label: 'en-GB',
    tags: ['English - United Kingdom']
  }, {
    id: 'en_IN',
    label: 'en-IN',
    tags: ['English - India']
  }, {
    id: 'hi_IN',
    label: 'hi-IN',
    tags: ['Hindi - India']
  }, {
    id: 'de_DE',
    label: 'de-DE',
    tags: ['German - Germany']
  }, {
    id: 'ja_JP',
    label: 'ja-JP',
    tags: ['Japanese - Japan']
  }, {
    id: 'fr_FR',
    label: 'fr-FR',
    tags: ['French - France']
  }, {
    id: 'fr_CA',
    label: 'fr-CA',
    tags: ['French - Canada']
  }, {
    id: 'es_ES',
    label: 'es-ES',
    tags: ['Spanish - Spain']
  }, {
    id: 'es_MX',
    label: 'es-MX',
    tags: ['Spanish - Mexico']
  }, {
    id: 'es_US',
    label: 'es-US',
    tags: ['Spanish - United States']
  }, {
    id: 'it_IT',
    label: 'it-IT',
    tags: ['Italian - Italy']
  }, {
    id: 'pt_BR',
    label: 'pt-BR',
    tags: ['Portuguese - Brazil']
  }, {
    id: 'ar_SA',
    label: 'ar-SA',
    tags: ['Arabic - Saudi Arabia']
  }],

  // Device type dropdown
  DEVICE_TYPE: [{
    id: 'ALEXA_3P',
    label: 'Alexa Enabled 3P Device'
  }, {
    id: 'VOX',
    label: 'Alexa Mobile Accessory Device'
  }, {
    id: 'ECHO_V2',
    label: 'Amazon Echo (2nd Gen)'
  }, {
    id: 'ECHO_V1',
    label: 'Amazon Echo (1st Gen)'
  }, {
    id: 'ECHO_AUTO',
    label: 'Amazon Echo Auto'
  }, {
    id: 'ECHO_DOT_V3',
    label: 'Amazon Echo Dot (3rd Gen)'
  }, {
    id: 'ECHO_DOT_V2',
    label: 'Amazon Echo Dot (2nd Gen)'
  }, {
    id: 'ECHO_DOT_V1',
    label: 'Amazon Echo Dot (1st Gen)'
  }, {
    id: 'ECHO_INPUT',
    label: 'Amazon Echo Input'
  }, {
    id: 'ECHO_LOOK',
    label: 'Amazon Echo Look'
  }, {
    id: 'ECHO_PLUS_V2',
    label: 'Amazon Echo Plus (2nd Gen)'
  }, {
    id: 'ECHO_PLUS',
    label: 'Amazon Echo Plus (1st Gen)'
  }, {
    id: 'ECHO_SHOW_V2',
    label: 'Amazon Echo Show (2nd Gen)'
  }, {
    id: 'ECHO_SHOW_V1',
    label: 'Amazon Echo Show (1st Gen)'
  }, {
    id: 'ECHO_SPOT',
    label: 'Amazon Echo Spot'
  }, {
    id: 'TAP',
    label: 'Amazon Tap'
  }],

  // DUT Type map for 1P devices
  DUT_TYPE_MAP: {
    'A7WXQPH584YP': 'ECHO_V2',
    'AB72C64C86AW2': 'ECHO_V1',
    'A303PJF6ISQ7IC': 'ECHO_AUTO',
    'A32DOYMUN6DTXA': 'ECHO_DOT_V3',
    'A3S5BH2HU6VAYF': 'ECHO_DOT_V2',
    'AKNO1N0KSFN8L': 'ECHO_DOT_V1',
    'A1JJ0KFC4ZPNJ3': 'ECHO_INPUT',
    'A1ORT4KZ23OY88': 'ECHO_LOOK',
    'A18O6U1UQFJ0XK': 'ECHO_PLUS_V2',
    'A2M35JJZWCQOMZ': 'ECHO_PLUS',
    'AWZZ5CVHX2CD': 'ECHO_SHOW_V2',
    'A1NL4BVLQ4L3N3': 'ECHO_SHOW_V1',
    'A10A33FOX2NUBK': 'ECHO_SPOT',
    'A38949IHXHRQ5P': 'TAP',
    'A1RTAM01W29CUP': 'A4PCDevice'
  },

  keys: [
    'testStatus',
    'payload',
    'fan',
    'DUT',
    'speakNineNinety', // Keeping old keys for backward compatibility
    'speakNineThirty',
    'speakThreeNinety',
    'speakThreeThirty',
    '1.8 m 135 deg', // New keys
    '2.7 m 90 deg',
    '2.7 m 30 deg',
    '0.9 m 90 deg',
    '0.9 m 30 deg',
    'HATS', // Close-Talk
    'Automotive Companion',
    'automotiveCompanion'
  ],


  labEnvMapping: {
    "General": ['1.8 m 135 deg',
    '2.7 m 90 deg',
    '2.7 m 30 deg',
    '0.9 m 90 deg',
    '0.9 m 30 deg'],
    "Automotive": ['Automotive Companion'],
    "Qual&RF": ['Qual and RF Companion'],
    "Comms" : ["Andy FirstFloor", "Andy SecondFloor", "Andy ThirdFloor", "Andy Echo", "Bob Kitchen", "Bob Echo", "Chris Kitchen","Chris Echo", "David Kitchen", "David Echo"]
  },

  actorMappingKeys: [
    '1.8 m 135 deg',
    '2.7 m 90 deg',
    '2.7 m 30 deg',
    '0.9 m 90 deg',
    '0.9 m 30 deg',
    'Automotive Companion',
    'Qual and RF Companion'
  ],

  // Location indexes to skip for Auto_local_search scenario while generating actor mapping
  autoLocalSearchSkipLocations: [0, 1, 2, 4],

  // Location indexes to skip for Functional scenario while generating actor mapping
  functionalSkipLocations: [0, 1, 2, 4],

  // Location indexes to skip for Mobile scenario while generating actor mapping
  mobileSkipLocations: [1, 2],

  // Location indexes to skip for Mobile Functional scenario type while generating actor mapping
  mobileFunctionalSkipLocations: [0, 1, 2, 3],

  // Location indexes to skip for Music scenario while generating actor mapping
  musicSkipLocations: [0, 1, 2, 4],

  // Location indexes to skip for Qual scenario while generating actor mapping
  qualSkipLocations: [3, 4],

  // Location indexes to skip for Stability scenario while generating actor mapping
  stabilitySkipLocations: [0, 1, 2, 4],

  // Location indexes to skip for UPL scenario while generating actor mapping
  uplSkipLocations: [0, 1, 2, 4],


  // default locations in Lab page.
  defaultLocation: {
    0: { scenario: 'fan', display: '1.8 m 135 deg' },
    1: { scenario: 'speakNineNinety', display: '2.7 m 90 deg' },
    2: { scenario: 'speakNineThirty', display: '2.7 m 30 deg' },
    3: { scenario: 'speakThreeNinety', display: '0.9 m 90 deg' },
    4: { scenario: 'speakThreeThirty', display: '0.9 m 30 deg' },
  },

  farFieldLocation: {
    0: { scenario: 'fan', display: '1.8 m 135 deg' },
    1: { scenario: 'speakNineNinety', display: '2.7 m 90 deg' },
    2: { scenario: 'speakNineThirty', display: '2.7 m 30 deg' },
  },

  nearFieldLocation: {
    0: { scenario: 'fan', display: '1.8 m 135 deg' },
    1: { scenario: 'speakThreeNinety', display: '0.9 m 90 deg' },
    2: { scenario: 'speakThreeThirty', display: '0.9 m 30 deg' },
  },

  nearFieldLocationDisplay: {
    0: { scenario: 'fan', display: '1.8 m 135 deg' },
    1: { display: 'N/A' },
    2: { display: 'N/A' },
    3: { scenario: 'speakThreeNinety', display: '0.9 m 90 deg' },
    4: { scenario: 'speakThreeThirty', display: '0.9 m 30 deg' },
  },

  musicLocationDisplay: {
    0: { scenario: 'fan', display: 'N/A' },
    1: { display: 'N/A' },
    2: { display: 'N/A' },
    3: { scenario: 'speakThreeNinety', display: '0.9 m 90 deg' },
    4: { display: 'N/A' }
  },

  functionalLocationDisplay: {
    0: { scenario: 'fan', display: 'N/A' },
    1: { display: 'N/A' },
    2: { display: 'N/A' },
    3: { scenario: 'speakThreeNinety', display: '0.9 m 90 deg' },
    4: { display: 'N/A' }
  },

  trainedMobileLocation: {
    0: { scenario: 'fan', display: 'N/A' },
    1: { scenario: 'speakThreeNinety', display: '0.9 m 90 deg' },
  },

  closeTalkLocation: {
    0: { scenario: 'fan', display: '2.0 m 90 deg' },
    1: { scenario: 'speakThreeNinety', display: '0.9 m 90 deg' },
  },

  closeTalkLocationDisplay: {
    0: { scenario: 'fan', display: '2.0 m 90 deg' },
    1: { display: 'N/A' },
    2: { display: 'N/A' },
    3: { scenario: 'speakThreeNinety', display: 'HATS' },
    4: { display: 'N/A' }
  },

  automotiveCompanionLocation: {
    0: { scenario: 'automotive', display: 'Automotive Companion' }
  },

  ACOUSTIC_LOCATION_TO_CLOSE_TALK_MAP: {
    '1.8 m 135 deg': '2.0 m 90 deg',
    '2.7 m 90 deg': 'N/A',
    '2.7 m 30 deg': 'N/A',
    '0.9 m 90 deg': 'HATS',
    '0.9 m 30 deg': 'N/A'
  },

  ACOUSTIC_LOCATION_TO_MUSIC_MAP: {
    '1.8 m 135 deg': 'N/A',
    '2.7 m 90 deg': 'N/A',
    '2.7 m 30 deg': 'N/A',
    '0.9 m 90 deg': '0.9 m 90 deg',
    '0.9 m 30 deg': 'N/A'
  },

  ACOUSTIC_LOCATION_TO_MOBILE_MAP: {
    '1.8 m 135 deg': '1.8 m 135 deg',
    '2.7 m 90 deg': 'N/A',
    '2.7 m 30 deg': 'N/A',
    '0.9 m 90 deg': '0.9 m 90 deg',
    '0.9 m 30 deg': 'Voice Enrollment'
  },

  /**
   * Map used to decide which locations to display on Labs page based on scenario whitelisting
  */
  labsPageLocationToScenarioMap: {
    'location': ['AVSCertSimulation'],
    'musiclocation': ['AVSCertMusic', 'AQTFunctional', 'AQTStabilityScenario', 'AQTUPLScenario', 'ABSPhysicalLabAcav'],
    'closetalklocation': ['AVSCertCloseTalk'],
    'mobilelocation': ['AVSCertMobile']
  },

  /**
   * TODO: Improve this logic. Add fields thats are needed instead of removing fields!!
   * Map used to decide which columns to display on Results page based on whitelisted scenarios
   * returned from controller
   *
   * Columns mentioned specific to testSuite will be removed
  */
  resultsPageColumnsMap: {
    'ALL': ['scenarioType', 'scenarioTypeFunc', 'scenarioTypeCloseTalk', 'scenarioTypeMobile',
      'scenarioTypeAutoLocalSearch', 'testType', 'msp', 'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'ACOUSTIC': ['msp', 'scenarioTypeFunc', 'scenarioTypeCloseTalk', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'MUSIC': ['scenarioType', 'scenarioTypeFunc', 'scenarioTypeCloseTalk', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'FUNCTIONAL': ['msp', 'scenarioType', 'scenarioTypeCloseTalk', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'CLOSE_TALK': ['msp', 'scenarioType', 'scenarioTypeFunc', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'MOBILE': ['msp', 'scenarioType', 'scenarioTypeFunc', 'scenarioTypeCloseTalk', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'AVS_SECURITY': ['scenarioType', 'scenarioTypeFunc', 'scenarioTypeCloseTalk', 'scenarioTypeMobile',
      'testType', 'msp', 'devicetype', 'scenarioTypeAutoLocalSearch', 'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'AUTO_LOCAL_SEARCH': ['msp', 'scenarioType', 'scenarioTypeCloseTalk', 'scenarioTypeMobile', 'scenarioTypeFunc', 'testType',
      'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'STABILITY': ['msp', 'scenarioType', 'scenarioTypeCloseTalk', 'scenarioTypeFunc', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'testType', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'UPL': ['msp', 'scenarioType', 'scenarioTypeCloseTalk', 'scenarioTypeFunc', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'testType', 'scenarioTypeStability', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'QUAL': ['msp', 'scenarioType', 'scenarioTypeFunc', 'scenarioTypeCloseTalk', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeCustomTest', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'CUSTOM_TEST': ['msp', 'scenarioType', 'scenarioTypeFunc', 'scenarioTypeCloseTalk', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeAcav', 'scenarioTypeAutomotive'],
    'ACAV': ['msp', 'scenarioType', 'scenarioTypeCloseTalk', 'scenarioTypeFunc', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'testType', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAutomotive'],
    'AUTOMOTIVE': ['msp', 'scenarioType', 'scenarioTypeCloseTalk', 'scenarioTypeFunc', 'scenarioTypeMobile', 'scenarioTypeAutoLocalSearch',
      'deviceVirtualAssistant', 'testType', 'scenarioTypeStability', 'scenarioTypeUPL', 'scenarioTypeQual', 'scenarioTypeCustomTest', 'scenarioTypeAcav'],
  },

  locationMap: {
    'fan': 'Noise',
    'DUT': 'DUT',
    'speakNineNinety': '2.7 m 90 deg',
    'speakNineThirty': '2.7 m 30 deg',
    'speakThreeNinety': '0.9 m 90 deg',
    'speakThreeThirty': '0.9 m 30 deg',
    'automotiveCompanion': 'Automotive Companion'
  },

  locationMapNearField: {
    'fan': 'Noise',
    'DUT': 'DUT',
    'speakThreeNinety': '0.9 m 90 deg',
    'speakThreeThirty': '0.9 m 30 deg'
  },

  noiseTypesMap: {
    'SILENCE': 'Silence',
    'LAPTOP': 'External Music',
    'DEVICE': 'Device Playback',
    'KITCHEN': 'Noise',
    'CAR': 'Car Noise',
    'CAFETERIA': 'Cafeteria',
    'PUB': 'Pub',
    'PINK': 'Pink',
    'ROCK': 'Rock Music',
    'OFFICE':'Office Noise',
    'AUTOMOTIVE_TRAFFIC_LIGHT_MODE': 'Traffic Light Mode',
    'AUTOMOTIVE_MEDIUM_SPEED': 'Medium Speed Mode',
    'AUTOMOTIVE_MEDIUM_SPEED_WINDOWS_OPEN': 'Medium Speed with Windows Open',
    'AUTOMOTIVE_HIGH_SPEED': 'High Speed Mode'
  },

  automotiveCustomOptions: {
    'AUTOMOTIVE_TRAFFIC_LIGHT_MODE': 'Traffic Light Mode',
    'AUTOMOTIVE_MEDIUM_SPEED': 'Medium Speed Mode',
    'AUTOMOTIVE_MEDIUM_SPEED_WINDOWS_OPEN': 'Medium Speed with Windows Open',
    'AUTOMOTIVE_MEDIUM_SPEED_DEVICE_PLAYBACK': 'Medium Speed with Device Playback',
    'AUTOMOTIVE_HIGH_SPEED': 'High Speed Mode',
    'AUTOMOTIVE_HIGH_SPEED_DEVICE_PLAYBACK': 'High Speed with Device Playback'
  },

  automotiveNoiseType: {
    'AUTOMOTIVE_DEVICE_PLAYBACK': 'Device Playback',
  },

  cache: {
    LABSPIS: 'getLabsAndRasPis',
    JOBS: 'getJobs',
    NEWRUN: 'newRun',
    CUSTOMSCENARIOS: 'getCustomScenarios'
  },

  rasPiAction: {
    STATE: { id: 'STATE', name: 'Ping', action: 'state', icon: 'status-in-progress' },
    UPDATE: { id: 'UPDATE', name: 'Update', action: 'update', icon: 'refresh' },
    SYNC: { id: 'SYNC', name: 'Sync Resources', action: 'sync', icon: 'caret-right-filled' },
    RESYNC: { id: 'RESYNC', name: 'Resync', action: 'sync', icon: 'refresh' },
    TALK: { id: 'TALK', name: 'Talk', action: 'talk', icon: 'upload' },
    STOP: { id: 'STOP', name: 'Stop', action: 'stop', icon: 'status-stopped' },
    CALIBRATE: { id: 'CALIBRATE', name: 'Start', action: 'state', icon: 'caret-right-filled' },
  },

  PINK_NOISE_FILE: 'Administrative_calibration.wav',
  AQT_SKILL_AUDIO_FILE: 'en_US_Administrative_alexa_play_task_one_from_skill.wav',
  ADMIN_STOP_AUDIO_FILE: 'en_US_Administrative_alexa_stop.wav',
  PINK_NOISE_FILE_TIMEOUT: 120000,

  ERROR: 'ERROR',
  NETWORKERR: 'Error submitting request to the AQT service!',
  CUSTOM_OPTIONS_ERROR: 'Please select at least one option',
  TEST_TYPES_ERROR: 'Please select the test type',
  TEST_CATEGORIES_ERROR: 'Please select the test category',
  LOCK_TYPES_ERROR: 'Please select the lock type',
  DEFAULT_DROPDOWN_ERROR: 'Please select a value from the dropdown',
  MISSING_DUTS_ERROR: 'No Devices were found for the selected customer ID. Click Refresh to retry '
    + ' or click Add to enter DSN manually',
  ERROR_MUSIC_SCENARIO_OPTIONS: 'Please select Test type for one or more Music scenarios',
  ERROR_MUSIC_MARKETPLACE: 'Please select the marketplace to run one or more scenarios',
  ERROR_EMPTY_MUSIC_SCENARIOS: 'Please select at least one Music scenario to run',
  LOCERR: 'Location missing',
  NOLABS: 'Service error retreiving labs for the account!',
  SESSIONERR: 'Your session has expired. Please log in again.',
  SESSION_EXPIRED_PARAM: 'sessionExpired=true',
  SESSION_EXPIRED_KEY: 'SESSION_EXPIRED',
  SESSION_EXPIRED_STATUS_CODE: 401,
  ROOT_URL: '/',
  NORASPI: 'Missing Raspberry Id!',
  NOCMDLINE: 'Command line cannot be retrieved at the moment!',
  NOJOBS: 'No jobs available!',
  NOURL: 'Report cannot be downloaded at this time!',
  VALIDATIONERR: 'Unexpected error during test submission. Please retry.',
  SERVERERR: 'Error from AQT service!',
  SERVERSEQERR: 'Error sequencing the test. Please retry after sometime!',
  INPUTERR: 'Field cannot be blank. Allowed characters - Alphanumeric, Hyphen (-) and Underscore (_)',
  INPUTERR_BLANK: 'Input cannot be blank',
  INPUTERR_INVALID_INPUT: 'Invalid input. Allowed characters are Alphanumeric, Hyphen (-), Underscore (_)',
  INPUTERR_INVALID_INPUT_BUILD_INFO: 'Invalid input. Allowed characters are Alphanumeric, Hyphen (-), Underscore (_) and Dot (.)',
  INPUTERR_ALPHANUMERIC: 'Invalid Input. Input should contain at least one Alphanumeric character',
  INPUTERR_EMAIL: 'Invalid input. Please enter a valid email address.',
  INPUTERR_TEXT_LENGTH: 'Value cannot exceed more than 30 characters.',
  INPUTERR_IHRT_ACC_ERR: 'Input cannot be blank. Please provide the iHeartRadio account email linked to your Alexa test account.',
  DUPLICATE_DSN_ERROR: 'Duplicate DSNs were provided for one or more fields. Please provide unique DSNs.',
  INPUT_FIELD_ERROR: 'Invalid input provided for one or more fields. Allowed characters are Alphanumeric, Hyphen (-), Underscore (_)',
  MISSING_DSN_ERROR: 'Please select a valid DSN for one or more devices.',
  MISSING_DSN_ERROR_MICLESS: 'Please select a valid DSN for the Mic-less/PCA device.',
  INVALID_DEVICE_CONFIG: 'DUT\'s device serial number (DSN) isn\'t matching with the DSN in the filename.',
  MISSING_DEVICE_CONFIG: 'N/A - Device config won\'t be in the report if this field is left blank',
  MISSING_BUILD_INFO_ERROR: 'Please provide Firmware information for one or more devices.',
  MISSING_BUILD_INFO_ERROR_MICLESS: 'Please provide Firmware information for the Mic-less/PCA device.',
  EMPTY_TRAINERS_ERROR: 'Please select one or more trainers from the dropdown',
  EMPTY_NOISES_ERROR: 'Please select one or more noises from the dropdown',
  REFRESH_DOWNLOAD_PAGE: 10000,
  NETWORK_ERROR: 'Network Error',
  JOB_QUEUE_LOAD_ERROR: 'Too many jobs in job queue. Please remove one or more jobs from job queue and retry.',
  STATUS_ERROR: ' Error',
  LOADING_DUTS: 'Loading DSNs for the selected customer ID ...',
  DUT_TOOLTIP_MESSAGE: 'Click Refresh button if you do not see desired DSNs in the dropdown',
  SEQUENTIAL_SCENARIO_INFO_MESSAGE: 'Scenarios will be executed in the order in which selected.' +
    ' Use "Reset Scenarios" to clear the selection. Also, click "Select Marketplace" button to select the marketplace' +
    ' after scenario selection has been done.',
  // Message to display on Live run page when there's no data to render from back end
  AUTO_SYNC_IN_PROGRESS_MESSAGE: 'Downloading required audio resources into RasPis for this test... Test will begin after resource sync is complete.',
  RETRIEVING_DATA_MESSAGE: 'Retrieving test information, it may take around a minute or two... Test data will be rendered after first utterance has executed.',
  JOB_QUEUED_MESSAGE: 'Test has been queued for the execution. Test data will be rendered after execution begins.',
  JOB_COMPLETED: 'Test completed.',
  RETRIEVING_DATA_MESSAGE_FAR: 'Retrieving FAR test information, Test data will be rendered as soon as the first measure query executed.',
  RETRIEVING_DATA_MESSAGE_SECURITY: 'Retrieving test information, it may take around a minute or two... Test data will be rendered as soon as logs are available.',
  // Maximum wait time in seconds after which if no data is rendered, Live run page will display RETRIEVING_DATA_MESSAGE above
  MAX_WAIT_TIME: 2,
  ADMIN_UTTERANCE_MESSAGE: 'Running prerequisite conditions for the test ... It may take around a minute or two.',
  TEST_COMPLETED_MESSAGE: 'Test completed. Please navigate to the results page to see the report.',
  TEST_CANCELED_MESSAGE: 'Test terminated. Please navigate to the results page to see the report.',
  REF_MIC_CONSENT_MESSAGE: 'By clicking Submit, I agree to audio recording during testing, including storage of the recordings ' +
    'for analysis and debugging purposes.',
  NEW_SOFTWARE_UPDATE_MESSAGE: 'There is a Software update. Please check the Release Notes tab for more details.',
  DEVICE_OFFLINE_MESSAGE: 'The Device was detected offline or muted during last measure.',
  FAR_DEVICE_FAIL_MESSAGE: 'This Device is going to fail the test due to more than 3 false wake counts.',
  STABILITY_DEVICE_FAIL_MESSAGE: 'This Test will most likely fail unless the pass rate recovers above 90% in the remaining attempts.',

  INVALID_DATE_RANGE: 'From date cannot be greater than To date',
  ADD_PRIMARY_DSN_REMINDER: 'Please select primary device information in TestGroupInfo Section before additional devices can be added here.',
  EMPTY: '',
  WHITESPACE: ' ',
  RETRIEVING: 'Loading',
  PROCESSING: 'inprogress',
  IDLE: 'IDLE',
  // Prerequisite messages to display on New Run page for Mobile Functional test categories
  ALEXA_HF_VERIFICATION_PREREQ: '* Prereq: User has Alexa app and DSP apk installed and is signed in to Alexa app.',
  DEVICE_LOCK_HF_VERIFICATION_PREREQ: '* Prereq: User has swiped lock before. For pin lock, user has set pin to one that AQT uses for testing.',
  APP_VERIFICATION_PREREQ: '* Prereq: All apps from PlayStore have been updated like DSP. Alexa App is installed but not signed in, and user has accepted the Terms and Conditions.',

  MORGAN_UBUNTU_LAB_CALIBRATION_TOOL_CMD: 'sudo aqt_auto',
  //Security related constatns
  POWER_OFF_DESC: "Kindly power off the DUT and click on Continue.",
  POWER_ON_DESC: "Kindly power on the DUT, connect to the hotspot and click on Continue.",


  SIMULATION_ENTITY_STATE: {
    IDLE: 'IDLE',
    HEARING: 'HEARING',
    SENDING: 'SENDING',
    PROCESSING: 'PROCESSING',
    RESPONDING: 'RESPONDING',
    EMITTING: 'EMITTING'
  },

  SIMULATION_ENTITY_DIRECTION: {
    OUT: 'out',
    IN: 'in'
  },

  DIAGRAM_COLORS: {
    INVISIBLE: "white",
    BLUE_ALEXA: "#5dc2ee",
    RED: "#cc5500",
    YELLOW: "#fff000"
  },

  DOWNLOAD_STATUS: {
    INITIAL: "initial",
    START: "start",
    IN_PROGRESS: "inprogress",
    COMPLETED: "complete",
    TIMEOUT: "Timeout",
    ERROR: "error",
    FAILED: "failed"
  },

  AWSUI_PROGRESS_BAR_STATUS: {
    IN_PROGRESS: 'in-progress',
    SUCCESS: 'success',
    ERROR: 'error'
  },

  iHeartEmailFieldId: 'musiciHeartEmailAddr',
  JOB_SEQUENCE_LIMIT: 25,
  A4PC_WAKEWORD_OPTIONS: [
    {
      "value": "SWW",
      "label": "Software"
    },
    {
      "value": "HWW",
      "label": "Hardware"
    }
  ]
}

const testTypes = [
  {
    label: 'Standard',
    index: 0,
    type: AppConstants.STANDARD
  },
  {
    label: 'Quick',
    index: 1,
    type: AppConstants.QUICK
  },
  {
    label: 'Demo',
    index: 2,
    type: AppConstants.DEMO
  },
  {
    label: 'Customized',
    index: 3,
    type: AppConstants.CUSTOMIZED
  }
]

const testCategories = [
  {
    id: AppConstants.ALEXA_HANDS_FREE_VERIFICATION,
    label: 'Alexa Hands-Free Verification',
    index: 0,
    type: AppConstants.ALEXA_HANDS_FREE_VERIFICATION
  },
  {
    id: AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION,
    label: 'Device Lock Hands-Free Verification',
    index: 1,
    type: AppConstants.DEVICE_LOCK_HANDS_FREE_VERIFICATION
  },
  {
    id: AppConstants.APP_VERIFICATION,
    label: 'App Verification',
    index: 2,
    type: AppConstants.APP_VERIFICATION
  }
]

const lockTypes = [
  {
    id: AppConstants.PIN_LOCK,
    label: 'Pin Lock',
    index: 0,
    type: AppConstants.PIN_LOCK
  },
  {
    id: AppConstants.SWIPE_LOCK,
    label: 'Swipe Lock',
    index: 1,
    type: AppConstants.SWIPE_LOCK
  }
]

const spoofedLocations = {
  en_US: {
    LOCALE_BASED_LOCATIONS: AppConstants.AVAILABLE_LOCATIONS_EN_US
  }
}

const testSuites = {
  QUAL: {
    ID: 'QUAL',
    MARKETPLACE: [{
      id: 'en_US',
      label: 'en-US',
      tags: ['English - United States']
    }],
    LABEL: 'Qual Scenario',
    SCENARIO_TYPE: [
      {
        id: 'QUAL_SILENCE',
        label: 'Silence',
        tags: ['Runs Silence scenario'],
      },
      {
        id: 'QUAL_DEVICE_PLAYBACK',
        label: 'Device Playback',
        tags: ['Runs Device Playback scenario'],
      },
      {
        id: 'QUAL_NOISE_PINK',
        label: 'Noise - Pink',
        tags: ['Runs Noise - Pink scenario'],
      },
      {
        id: 'QUAL_NOISE_PUB',
        label: 'Noise - Pub',
        tags: ['Runs Noise - Pub scenario'],
      },
      {
        id: 'QUAL_NOISE_ROCK',
        label: 'Noise - Rock',
        tags: ['Runs Noise - Rock scenario'],
      },
      {
        id: 'QUAL_NOISE_CAFE',
        label: 'Noise - Cafe',
        tags: ['Runs Noise - Cafe scenario'],
      },
      {
        id: 'QUAL_NOISE_NEWS',
        label: 'Noise - News',
        tags: ['Runs Noise - News scenario'],
      },
      {
        id: 'QUAL_WWDD',
        label: 'WWDD',
        tags: ['Runs WWDD scenario'],
      }
    ],
    SPEECH_LOCATIONS: [
      "2.7 m 30 deg",
      "2.7 m 90 deg"
    ],
    DEFAULT_SPEECH_LOCATION: "2.7 m 90 deg",
    DEFAULT_NOISE_LOCATION: AppConstants.NOISE_LOCATION,
    CLASSIFICATION: {
      QUAL_SILENCE: 'SILENCE',
      QUAL_DEVICE_PLAYBACK: 'DEVICE_PLAYBACK',
      QUAL_NOISE_PINK: 'NOISE',
      QUAL_NOISE_PUB: 'NOISE',
      QUAL_NOISE_ROCK: 'NOISE',
      QUAL_NOISE_CAFE: 'NOISE',
      QUAL_NOISE_NEWS: 'NOISE',
      QUAL_WWDD: 'WWDD'
    },
    CALIBRATION_FILES: {
      SPEECH: 'Noise_for_speech_calibration.wav',
      QUAL_DEVICE_PLAYBACK: AppConstants.AQT_SKILL_AUDIO_FILE,
      QUAL_NOISE_PUB: 'Pub_mono_noisenorm_equivalentnoise_16s.wav',
      QUAL_NOISE_NEWS: 'NPRnews_noisenorm_equivalentnoise_16s.wav',
      QUAL_NOISE_PINK: 'Pink_noisenorm_equivalentnoise_16s.wav',
      QUAL_NOISE_CAFE: 'Cafeteria_mono_noisenorm_equivalentnoise_16s.wav',
      QUAL_NOISE_ROCK: 'ETSIRock_norm_equivalentnoise_16s.wav',
    },
    TEST_TYPES: ['STANDARD', 'CUSTOMIZED']
  },
  ACOUSTIC: {
    ID: 'ACOUSTIC',
    MARKETPLACE: AppConstants.MARKETPLACE,
    LABEL: AppConstants.ACOUSTIC_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.SCENARIO_TYPE
  },
  MUSIC: {
    ID: 'MUSIC',
    MARKETPLACE: AppConstants.MARKETPLACE_MUSIC,
    LABEL: MusicConstants.MUSIC_SCENARIO_LABEL,
    SCENARIO_TYPE: MusicConstants.MSP_TYPE
  },
  FUNCTIONAL: {
    ID: 'FUNCTIONAL',
    MARKETPLACE: AppConstants.MARKETPLACE_FUNCTIONAL,
    LABEL: AppConstants.FUNCTIONAL_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.FUNCTIONAL_SCENARIO_TYPE,
    SCENARIO_TYPE_ALPHA: AppConstants.FUNCTIONAL_SCENARIO_TYPE_ALPHA
  },
  STABILITY: {
    ID: 'STABILITY',
    MARKETPLACE: AppConstants.MARKETPLACE_STABILITY,
    LABEL: AppConstants.STABILITY_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.STABILITY_SCENARIO_TYPE
  },
  CLOSE_TALK: {
    ID: 'CLOSE_TALK',
    MARKETPLACE: AppConstants.MARKETPLACE_CLOSE_TALK,
    LABEL: AppConstants.CLOSE_TALK_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.CLOSE_TALK_SCENARIO_TYPE
  },
  MOBILE: {
    ID: 'MOBILE',
    MARKETPLACE: AppConstants.MARKETPLACE_TRAINED_MOBILE,
    OVERHAULED_MARKETPLACE: AppConstants.MARKETPLACE_TRAINED_MOBILE_OVERHAULED,
    LABEL: AppConstants.MOBILE_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.MOBILE_SCENARIO_TYPE
  },
  AUTO_LOCAL_SEARCH: {
    ID: 'AUTO_LOCAL_SEARCH',
    MARKETPLACE: AppConstants.MARKETPLACE_AUTO_LOCAL_SEARCH,
    LABEL: AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.AUTO_LOCAL_SEARCH_SCENARIO_TYPE
  },
  UPL: {
    ID: 'UPL',
    MARKETPLACE: AppConstants.MARKETPLACE_UPL,
    LABEL: AppConstants.UPL_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.UPL_SCENARIO_TYPE
  },
  AVS_SECURITY: {
    ID: 'AVS_SECURITY',
    LABEL: AppConstants.SECURITY_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.AVS_SECURITY_SCENARIO_TYPE,
    MARKETPLACE: AppConstants.MARKETPLACE
  },
  CUSTOM_TEST: {
    ID: 'CUSTOM_TEST',
    LABEL: AppConstants.CUSTOM_SCENARIO_LABEL,
    SCENARIO_TYPE: [],
    MARKETPLACE: AppConstants.MARKETPLACE
  },
  AUTOMOTIVE: {
    ID: 'AUTOMOTIVE',
    MARKETPLACE: [...AQTLocales.US, ...AQTLocales.EU, ...AQTLocales.JP],
    LABEL: AppConstants.AUTOMOTIVE_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.AUTOMOTIVE_SCENARIO_TYPE,
    TEST_TYPES: ['STANDARD', 'DEMO']
  },
  ACAV: {
    ID: 'ACAV',
    MARKETPLACE: AppConstants.MARKETPLACE_ACAV,
    LABEL: AppConstants.ACAV_SCENARIO_LABEL,
    SCENARIO_TYPE: AppConstants.ACAV_SCENARIO_TYPE
  }
};


const BETA_MORGAN_EU_LOCALES = {
  NAME: 'EU Locales',
  MARKETPLACE: [...AQTLocales.EU],
  // since scenario and locales are tied together at this point,
  // manage a list to scenarios available for the beta locales
  // this can also be used to add beta use only scenarios if applicable
  ALLOW_LISTED_SCENARIOS: {
    // copy of acoustic scenarios available for the beta locales
    AUTOMOTIVE: {
      SCENARIO_TYPE: AppConstants.AUTOMOTIVE_SCENARIO_TYPE
    }
  }
};

const BETA_MORGAN_EN_IN_LOCALES = {
  NAME: 'enIN Locales',
  MARKETPLACE: [AQTLocales.enIN],
  // since scenario and locales are tied together at this point,
  // manage a list to scenarios available for the beta locales
  // this can also be used to add beta use only scenarios if applicable
  ALLOW_LISTED_SCENARIOS: {
    // copy of acoustic scenarios available for the beta locales
    AUTOMOTIVE: {
      SCENARIO_TYPE: AppConstants.AUTOMOTIVE_SCENARIO_TYPE
    }
  }
};

const BETA_MORGAN_FR_LOCALES = {
  NAME: 'FR Locales',
  MARKETPLACE: [AQTLocales.frCA, AQTLocales.esMX],
  // since scenario and locales are tied together at this point,
  // manage a list to scenarios available for the beta locales
  // this can also be used to add beta use only scenarios if applicable
  ALLOW_LISTED_SCENARIOS: {
    // copy of acoustic scenarios available for the beta locales
    AUTOMOTIVE: {
      SCENARIO_TYPE: AppConstants.AUTOMOTIVE_SCENARIO_TYPE
    }
  }
};


const BETA_MORGAN_CUSTOM_LOCALES = {
  NAME: 'Morgan Custom Locales',
  MARKETPLACE: [AQTLocales.enGB, AQTLocales.esUS, AQTLocales.deDE],
  // since scenario and locales are tied together at this point,
  // manage a list to scenarios available for the beta locales
  // this can also be used to add beta use only scenarios if applicable
  ALLOW_LISTED_SCENARIOS: {
    // copy of acoustic scenarios available for the beta locales
    AUTOMOTIVE: {
      SCENARIO_TYPE: AppConstants.AUTOMOTIVE_SCENARIO_TYPE
    }
  }
}

const BETA_MORGAN_ALL_LOCALES = {
  NAME: 'Morgan All Locales',
  MARKETPLACE: [...AQTLocales.US, ...AQTLocales.EU, ...AQTLocales.JP, AQTLocales.arSA],
  // since scenario and locales are tied together at this point,
  // manage a list to scenarios available for the beta locales
  // this can also be used to add beta use only scenarios if applicable
  ALLOW_LISTED_SCENARIOS: {
    // copy of acoustic scenarios available for the beta locales
    AUTOMOTIVE: {
      SCENARIO_TYPE: AppConstants.AUTOMOTIVE_SCENARIO_TYPE
    }
  }
};

// BETA use definitions
const BETA = {
  // list of locales available for beta use
  BETA_LOCALES: [
    BETA_MORGAN_EU_LOCALES,
    BETA_MORGAN_ALL_LOCALES,
    BETA_MORGAN_EN_IN_LOCALES,
    BETA_MORGAN_FR_LOCALES,
    BETA_MORGAN_CUSTOM_LOCALES,
  ],

  // provides more access control of the features to be beta allowlisted
  // this is required in-order to disable a particular feature across wide set of partners
  BETA_FEATURES_ENABLED: [
    BETA_MORGAN_EU_LOCALES,
    BETA_MORGAN_ALL_LOCALES,
    BETA_MORGAN_EN_IN_LOCALES,
    BETA_MORGAN_FR_LOCALES,
    BETA_MORGAN_CUSTOM_LOCALES,
  ],

  // TODO: this will be moved to Dynamo db customer allowlisted table
  // each company is defined the BETA FEATURES array indicating the features to be enabled
  BETA_PARTNERS: {
    "00906e17-b01d-4ab4-8175-14b89fc594ca": [
      BETA_MORGAN_ALL_LOCALES  // abdul aqtnw+beta@ - beta
    ],
    "9c46e48e-1cef-4216-b385-e979e4482d7a": [
      BETA_MORGAN_ALL_LOCALES  // abdul aqtnw@ - prod
    ],
    "065f8b8a-e007-42aa-9ab4-5ef92cf18813": [
      BETA_MORGAN_ALL_LOCALES  // aqt+prod
    ],
    "e02dc314-97f3-4713-b165-b7c1ccc796be": [
      BETA_MORGAN_ALL_LOCALES  // bagij@lab126.com & jinghliu@amazon.com
    ],
    "c8429faa-9e83-4fffbcd5-348ea27b0800": [
      BETA_MORGAN_ALL_LOCALES
    ],
    "81f0d8dc-7f9f-42e2-aa7e-421db6c6e3bf": [
      BETA_MORGAN_ALL_LOCALES // rivian
    ],
    "125458e1-bada-47e9-97b1-49a1d33430f7": [
      BETA_MORGAN_ALL_LOCALES // dekra
    ],
    "4d05f49a-f479-4f5c-a6b6-053ab3855c30": [
      BETA_MORGAN_ALL_LOCALES // autocert-aqt@amazon.com
    ],
    "479c7512-922c-4776-a086-2a9f8cb679ff": [
      BETA_MORGAN_ALL_LOCALES // autocert-aqt@amazon.com
    ],
    "14a24897-3c8b-4921-8650-d546a8cfd7cb": [
      BETA_MORGAN_EN_IN_LOCALES // harman
    ],
    "88ccbdf3-05aa-4533-9c2a-3eb86a8d29cd": [
      BETA_MORGAN_FR_LOCALES // VW
    ],
    "144cbfc8-1816-46fc-83c2-3f98dbdb8861": [
      BETA_MORGAN_CUSTOM_LOCALES // Fisker
    ]
  }
}

export default {
  ...AppConstants,
  TEST_SUITES: testSuites,
  AVAILABLE_SPOOFED_LOCATIONS: spoofedLocations,
  TEST_TYPES: testTypes,
  TEST_CATEGORIES: testCategories,
  LOCK_TYPES: lockTypes,
  BETA: BETA
};
